.select-account {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5b5757;
  text-align: center;
}
