.wrapper {
  padding: 8px 15px;
  box-sizing: border-box;
  border: 1px solid #c5d8ec;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgb(239 236 236 / 50%);
}

.create-form label {
  color: whitesmoke !important;
  font-family: "Montserrat", sans-serif;
  font-size: 12px !important;
}

.MuiTypography-root {
  font-size: 0.875rem !important;
}

.MuiInputLabel-root {
  font-size: 0.875rem !important;
}

.tab-panels .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
}

.latitude-container {
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  left: 1rem;
}

.latitude-container label,
.longitude-container label {
  color: black;
  text-shadow: 0px 0px 0px #1e1903;
}

.latitude-container input,
.longitude-container input {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 5rem;
  height: 32px;
  padding: 0px 12px;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px;
  font-size: 14px;
  outline: none;
}

.longitude-container {
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  left: 7rem;
}

.edit-profile-logo-container {
  position: relative;
  height: 85%;
  border: 1px solid #ddd;
}

.edit-profile-icon {
  display: flex !important;
  position: relative;
  margin: auto;
  font-size: 10rem !important;
}

.edit-btn-container {
  position: relative;
  height: 15%;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.edit-btn-container input {
  display: none;
}

.del-panels .MuiBox-root {
  padding: 0;
}

.MuiTooltip-tooltip {
  font-size: 0.75rem !important;
}

.e-multiselect {
  margin-top: 7px !important;
}

.pac-container {
  z-index: 9999;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
  font-size: 0.75rem !important;
  max-width: 64px;
}

.access-token {
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.disable-link {
  pointer-events: none;
}
