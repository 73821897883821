.edit-profile-logo-container {
  position: relative;
  height: 85%;
  border: 1px solid #ddd;
  width: 30%;
}

.edit-profile-icon {
  display: flex !important;
  position: relative;
  margin: auto;
  top: 15%;
  font-size: 10rem !important;
}

.edit-btn-container {
  position: relative;
  height: 15%;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.edit-btn-container input {
  display: none;
}
